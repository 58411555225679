.disabled-size-data{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, 60%);
    font-size: 14px;
    font-weight: normal;
}
.steps-notification{
    border: 1px solid #696969;
    padding: 2.5px;
    color: #696969;
}
.z-index-top{
    z-index:100;
}