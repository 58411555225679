.input-text{
    display: inline-block;
    width: 75px;
    height: 30px;
    padding: 5px;
}
.select{
    display: inline-block;
    font-size: 14px;
    width: 25%;
    padding: 0;
    height: 25px;
}

label{
    font-size: 14px!important;
}
.foundation_scheme{
    position: relative;
}
.foundation_scheme .tracks{
    position: absolute;
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.foundation_scheme input{
    width: 55px;
    height: 25px;
    text-align: center;
}

.pillar .track-h_found{
    top: 70px;
    -webkit-transform: rotate(90deg);
    transform: rotate(-90deg);
    right: 117px;
}

.pillar .track-up {
    left: 290px;
    top: -31px;
}

.pillar .track-surface {
    left: 156px;
    top: 5px;
}

.pillar .track-under {
    left: 347px;
    top: 138px;
}

.pillar .track-deep{
    left: 180px;
    top: 12px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}