.notificationTitle{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    transition:0.5s;
}
/*.notificationTitle:hover{*/
/*    cursor: pointer;*/
/*    color: #ddd;*/
/*    transition:0.5s;*/
/*}*/

.notification-container a{
    display: block;
    color: #333;
    text-decoration: none;
    transition:0.5s;
}
.notification-container a:hover{
    color: #ddd;
    text-decoration: none;
    transition:0.5s;
}