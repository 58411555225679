.container-loads{
    /*width: 1290px!important;*/
    margin-left: 0;
    margin-top: 0;
}

/*#loadsFile + label{*/
/*    padding: 5px 15px;*/
/*    cursor: pointer;*/
/*    background-color: #969696;*/
/*    color: #fff;*/
/*    font-weight: bold;*/
/*    margin-left: 0;*/
/*    -webkit-border-radius: 2.5px;*/
/*    -moz-border-radius: 2.5px;*/
/*    border-radius: 2.5px;*/
/*    transition: 0.3s;*/
/*    margin-bottom: 0;*/
/*}*/

/*#loadsFile + label:hover{*/

/*    background-color: #343434;*/
/*    transition: 0.3s;*/

/*}*/

.download-loads{

    padding: 5px 15px;
    cursor: pointer;
    background-color: #969696;

    font-weight: bold;
    margin-left: 10px;
    -webkit-border-radius: 2.5px;
    -moz-border-radius: 2.5px;
    border-radius: 2.5px;
    transition: 0.3s;
    margin-bottom: 0;
}
.download-loads:hover{

    background-color: #343434;
    transition: 0.3s;

}
.download-loads a{
    text-decoration: none;
    color: #fff;
}
.download-loads a:hover{
    text-decoration: none;
    color: #fff;
}
