.modal {
    overflow: scroll!important;
}

.invisible {
    display: none!important;
}

.no-margin {
    margin: 0!important;
}

label.btn-sm {
    line-height: 24px!important;
}

.btn-group-vertical .btn {
    margin: 0!important;
}

.btn-group-vertical .btn:not(:last-child) {
    border-bottom: none!important;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 5px;
}

.btn.disabled, .btn:disabled {
    opacity: 0.45;
}

.app-main-window {
    height: 100vh;
    background-color: #545454;
}

.main-window-container {
    position: relative;
    height: calc(100% - 46px);
    /*text-align: center;*/
    background-color: #545454;
}

.chart-container {
    width: 100%;
    height: calc(100vh - 91px);
    display: block;
    border: none!important;
    background-color: #fff;
    position: relative;
    overflow: scroll;
}

.foundation_scheme svg:not(.svg-inline--fa) {
    width: 100%;
    height: 100%;
    display: block;
    border: none!important;
}

rect {
    -webkit-transform-origin: 50% 50% 0;
    -moz-transform-origin: 50% 50% 0;
    -o-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
}

.rectButton {
    position: absolute;
    top: 20px;
    left: 180px;
}

button.rotate {
    position: absolute;
    top: 20px;
    left: 100px;
}

.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.no-padding {
    padding: 0!important;
    display: flex;
}

.no-margin {
    margin: 0!important;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.tooltip .tooltip-inner {
    background:#000!important;
}

.tooltip.in {
    filter: alpha(opacity=80);
    opacity:0.8;
}

/*CSS for tooltip recoloring is handled by the .tooltip-inner class as you noticed*/
.tooltip-inner {
    max-width: 300px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    background-color: #000;
    border-radius: .25rem;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #000 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #000 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #000 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #000 !important;
}

.main-window-content {
    height: 100%;
    position: relative;
    background-color: #545454;
    border-left: 1px solid #424242;
}

.main-window-content .has-panel-instrument {
    margin-left: 47px;
}

.main-window-content input[type=checkbox], input[type=radio] {
    vertical-align: text-top!important;
}

.main-window-content .tooltip {
    z-index: 3
}

#section-builder-calculations-nav table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed
}

#section-builder-calculations-nav table td,
#section-builder-calculations-nav table th {
    border: 1px solid;
    text-align: center!important;
    padding: 1px
}

#section-builder-calculations-nav table tr th {
    width: 80px;
    border-right-width: 0
}

#section-builder-calculations-nav table tr td:nth-child(2) {
    border-right-width: 0;
    border-left-width: 0;
    text-align: left;
    width: 20px
}

#section-builder-calculations-nav table tr td:nth-child(3) {
    border-right-width: 0;
    border-left-width: 0;
    text-align: right
}

#section-builder-calculations-nav table tr td:nth-child(4) {
    border-left-width: 0;
    text-align: left;
    padding-left: 5px;
    width: 40px
}

#section-builder-calculations-nav a#sb-calc-table-hide.closebtn {
    z-index: 20
}

#section-builder-calculations-nav #sb-results-tabs {
    margin-top: 10px
}

.section-result-table-heading {
    margin-bottom: 3px;
    margin-top: 12px
}

#sb-my-library-file-select,
#sb-templates,
#section-library-file-select {
    width: 100%;
    padding: 3px 5px;
    margin-bottom: 8px
}

.chart-container {
    overflow: auto!important;
    overflow-y: scroll!important;
    overflow-x: hidden!important;
}

.chart-container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
    background-color: #424242;
}

.chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: #545454;
}

.container-vtabs-menu {
    overflow: auto!important;
    overflow-y: scroll!important;
    overflow-x: hidden!important;
}

.container-report-item {
    scrollbar-width: none;
}

.container-report-item::-webkit-scrollbar {
    display: none;
}

.container-sidebar {
    box-sizing: border-box;
    width: 350px;
    top: 0;
    text-align: left;
    z-index: 1;
    color: #fff;
    background-color: #545454;
    overflow: auto!important;
    overflow-y: scroll!important;
    overflow-x: hidden!important;
    border: 1px solid #424242;
    font-size: 14px;
    position: relative;
}

.container-sidebar hr {
    margin-top: 0!important;
    margin-bottom: 0!important;
    border: 0;
    border-top: 1px solid #6c757b;
}

/**  STYLE 1 */
.container-sidebar::-webkit-scrollbar {
    width: 5px;
    background-color: #545454;
}

.container-sidebar::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
    background-color: #424242;
}

.container-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: #545454;
}

.container-sidebar table {
    color: #fff;
}

.container-sidebar-inner {
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
}

.container-sidebar-inner .tab-content {
    margin-top: 15px;
    padding: 0!important;
}

.container-sidebar-inner .nav-tabs .nav-link {
    display: block;
    padding: 5px 10px!important;
}

.container-sidebar-inner.padding-bottom {
    padding-bottom: 20px;
}

.container-sidebar .btn-controls {
    margin-bottom: 10px;
}

.container-sidebar .btn-controls .btn {
    margin-left: 5px;
}

.container-sidebar .btn-controls .btn:first-child {
    margin-left: 0!important;
}

.container-sidebar .form-check label.form-check-label {
    font-weight: normal;
    line-height: 24px;
}

.container-sidebar .form-group {
    margin-bottom: 7px;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 7px;
}

.container-sidebar .form-group label, .container-sidebar label {
    font-weight: normal;
    margin-bottom: 3px;
    font-size: 14px!important;
    opacity: 1;
    color: #fff;
}

.container-sidebar hr {
    margin-top: 10px
}

.container-sidebar .btn-light {
    color: #333!important;
    border: 1px solid #ced4da;
}

.container-sidebar .btn-light.active {
    color: #333!important;
    /*border: 1px solid #aaa!important;*/
}

.container-sidebar a {
    font-size: 14px;
    color: #fff;
}

.container-sidebar a:hover, .container-sidebar a:focus {
    color: #fff;
}

#section-builder-right-nav {
    width: 350px;
    top: 0;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    z-index: 1;
}

#section-builder-right-nav {
    display: none;
    /*opacity: 0;*/
}

.container-sidebar input[type=text] {
    padding: 6px 8px;
    /*margin-bottom: 8px;*/
    border-radius: 4px;
    border-width: 1px;
    border-color: #ced4da;
    display: block;
    font-size: 13px!important;
    /*width: 100%;*/
    box-sizing: border-box;
}

.container-sidebar select {
    font-size: 13px!important;
    border-width: 1px;
    border-color: #ced4da;
}

.container-sidebar .form-control {
    display: block;
    /*width: 100%;*/
    height: 34px!important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container-sidebar select.form-control {
    padding: 0.375rem 0.375rem;
}

.container-sidebar .input-group-text {
    height: 34px!important;
}

#section-builder-right-nav label {
    font-weight: 700;
    font-size: 13px;
}

.container-sidebar .content {
    font-size: 13px;
    color: #5a6c74
}

.container-sidebar .title.active {
    background-color: #289dcc!important;
    color: #fff!important
}

.container-sidebar .title {
    color: #289dcc!important;
    padding: 8px;
    font-size: 13px;
    font-weight: 700
}

.container-sidebar div#sb-results-tabs,
.container-sidebar div#section-builder-tabs {
    background: 0 0;
    border: none;
    margin-bottom: 30px
}

.container-sidebar div#sb-results-tabs ul,
.container-sidebar div#section-builder-tabs ul {
    background: 0 0;
}

.container-sidebar div#sb-results-tabs li a,
.container-sidebar div#section-builder-tabs li a {
    color: #fff;
    font-size: 13px!important;
    font-weight: 700
}

.container-sidebar div#sb-results-tabs li a, .container-sidebar div#section-builder-tabs li a.active {
    color: #000!important;
}

.container-sidebar .ui-state-active a {
    color: #000!important;
    /*background: #289dcc!important*/
}

.container-sidebar div#sb-results-tabs li.ui-state-active,
.container-sidebar div#section-builder-tabs li.ui-state-active {
    border-color: #289dcc!important;
    background: 0 0
}

.container-sidebar div#sb-results-tabs li.ui-state-hover,
.container-sidebar div#section-builder-tabs li.ui-state-hover {
    background: #289dcc!important
}

.container-sidebar div#sb-results-tabs li.ui-state-hover a,
.container-sidebar div#section-builder-tabs li.ui-state-hover a {
    background: #289dcc!important;
    color: #fff!important
}

.container-sidebar div.ui-tabs-panel {
    border-width: 1px;
    margin-top: 15px;
}

.container-sidebar div#section-builder-tabs ul a {
    padding: 8px
}

.container-sidebar div#section-builder-tabs hr {
    margin-bottom: 10px!important
}

.container-sidebar div#section-builder-tabs input[type=radio] {
    vertical-align: middle
}

#sb-left-nav-accordions {
    margin-bottom: 20px;
    display: none
}

.sb-left-nav-custom-buttons {
    width: 200px;
    margin-bottom: 10px!important;
    text-align: left!important
}

.container-sidebar .ui-accordion-header {
    color: #289dcc;
    font-size: 13px!important
}

.container-sidebar .ui-accordion-header.ui-state-hover {
    color: #fff;
    background: #289dcc
}

.container-sidebar .ui-accordion-header.ui-state-active {
    color: #fff;
    background: #289dcc!important;
    border-color: #289dcc!important
}

.container-sidebar .ui-accordion-header.ui-state-focus {
    color: #fff;
    background: #289dcc!important
}

.container-sidebar .accordion-icon {
    font-size: 18px;
    margin-right: 5px
}

#sb-material-form {
    padding: 4px 4px;
    margin-bottom: 8px;
    font-size: 13px;
    width: 100%;
    box-sizing: border-box
}

#mirror-y-form,
#mirror-z-form {
    width: 100%
}

.canvas-board-container {
    top: 0;
    left: 0;
    position: absolute;
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.canvas-board-container input.dimension-input[type=text] {
    font-weight: bolder;
    width: 60px!important;
    border: none;
    background-color: rgba(100, 100, 200, .1);
    color: #00f;
    font-size: 12px!important;
    position: absolute!important;
    z-index: 50!important;
    padding: 3px 6px!important;
    margin: 0!important;
    max-width: 200px!important
}

.root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-container {
    margin: auto;
    max-height: 100%;
    width: 100%;
    overflow: auto;
    background-color: #ffffff;
    overflow-x: hidden;
}

.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    height: 400px;
    width: 600px;
}


.section-board {
    height: 100%;
    width: 100%;
    border: none!important;
    border-radius: 0;
    background-color: #fff;
}

.btn-main-type-sections {
    margin-top: 12px;
}

#poly_shape_type_subtitle {
    font-style: italic;
    margin-top: 0!important
}

#section-builder-logo {
    z-index: 100;
    opacity: .85;
    bottom: 50px;
    right: 10px;
    width: 170px;
    position: absolute
}

#sb-cycle-polygon {
    z-index: 100;
    bottom: 10px;
    left: 10px;
    position: absolute;
    height: 100px;
    overflow: auto;
    width: 220px;
    padding: 3px 5px
}

.container-sidebar form div label {
    text-decoration: none;
    font-size: 14px;
    color: #ccc;
    /*display: inline;*/
    transition: .3s;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none
}

.main-window-content .form-group {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    /*margin-bottom: 5px!important;*/
}

.container-sidebar form div label:hover {
    color: #f1f1f1
}

#sb-right-nav-trash-can {
    color: #dc3545;
    font-size: 26px
}

.container-sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 13px;
    font-size: 26px;
    line-height: 27px;
    margin-left: 15px;
    text-decoration: none;
    color: #a5a5a5;
    margin-top: 5px;
}

.flip-horizontal {
    -webkit-transform: scale(-1,1);
    transform: scale(-1,1);
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: "FlipH";
}

#sb-right-nav-trash-can:hover,
.container-sidebar .closebtn:hover {
    text-decoration: none;
    color: #fff;
}

.sb-back-button {
    background: #289dcc;
    border-width: 1px 1px 1px 0;
    border-radius: 0;
    height: 28px;
    line-height: 28px;
    margin-left: 20px;
    margin-right: 2px;
    padding: 0 7px 0 3px;
    position: relative;
    text-decoration: none;
    margin-bottom: 15px;
    margin-top: 3px;
    display: none
}

.sb-back-button:before {
    background: #289dcc;
    border-width: 0 0 1px 1px;
    border-radius: 2px 0 2px 2px;
    content: '';
    display: block;
    height: 22px;
    left: -11px;
    position: absolute;
    top: 3px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 21px
}

.sb-back-button:hover,
.sb-back-button:hover:before {
    background: #286090;
    cursor: pointer
}

.sb-back-button span {
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    position: relative
}

.container-sidebar button:hover {
    cursor: pointer
}

#sb-section-identifier-name {
    display: none;
    float: right;
    font-weight: 700
}

#sb-mylibrary-input-field {
    width: 65%
}

#sb-templates-help {
    width: 100%
}

#section-library-loc a {
    text-decoration: none
}

#section-library-loc a:hover {
    color: #000
}

.section-datasheet tbody td {
    background-color: transparent!important
}

.sb-field-unit-container {
    position: relative;
    width: 100%;
    display: inline-block
}

.sb-field-unit-container .sb-unit {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #666;
    font-size: 12px
}

.sb-field-unit-container .react-numeric-input + .sb-unit, .sb-field-unit-container .react-numeric-input + .sb-unit {
    right: 25px;
}

#sb-tools-cl-dis {
    float: left;
    width: 47%;
    margin-bottom: 10px;
    text-align: left
}

#sb-tools-ds-dis {
    float: left;
    width: 47%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left
}

#sb-tools-dh-dis {
    float: right;
    width: 47%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left
}

#sb-tools-sc-dis {
    float: right;
    width: 47%;
    margin-bottom: 10px;
    text-align: left
}

.sb-text-headings {
    font-size: 16px;
    color: #f1f1f1;
    border-bottom: 1px solid #f1f1f1
}

#sb-unit-system-container {
    margin-bottom: 20px
}

label[for=sb-unit-system] {
    width: 47%;
    text-align: right;
    vertical-align: middle;
    font-weight: 700
}

#sb-unit-system {
    float: right;
    width: 47%;
    padding: 4px 8px;
    margin-bottom: 0
}

@media only screen and (max-width:1000px) {
    #section-builder-logo {
        display: none
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 960px;
    }
}

#sb-loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0
}

.sb-error-dimension {
    border-width: 1px!important;
    border-style: solid!important;
    border-color: #cd0a0a!important;
    background-color: #fde0d5!important;
    color: #cd0a0a!important
}

.section-builder-report-container {
    height: calc(100% - 50px);
    position: relative;
    display: none;
    text-align: left
}

#sb-report-left {
    width: 350px;
    top: 0;
    float: left;
    position: relative;
    box-sizing: border-box;
    border-left: 1px solid #28354b
}

.sb-report-material-summary-table th,
.sb-report-shape-summary-table th {
    background-color: #dcdcdc;
    font-weight: bolder;
    color: #000;
    border: 1px solid;
    text-align: center!important;
    padding: 1px;
    width: 23%;
    border-right-width: 0
}

.sb-report-material-summary-table td,
.sb-report-shape-summary-table td {
    border: 1px solid;
    text-align: center!important;
    padding: 1px
}

.sb-report-material-summary-table tr,
.sb-report-shape-summary-table tr {
    width: 23%;
    border-right-width: 0
}

.sb-report-material-summary-table,
.sb-report-shape-summary-table {
    width: 100%;
    margin-bottom: 20px
}

#sb-dimension-error {
    display: none;
    font-size: 13px
}

input.sb-dim-focus {
    box-sizing: border-box;
    border-color: #66f!important;
    box-shadow: inset 0 0 0 3px #66f!important
}

.sb-calcContent-S3D-div {
    position: absolute;
    top: 0;
    right: 0
}

.sb-calcContent-S3D-div table {
    width: 200px;
    text-align: center!important;
    border: 1px #ddd solid;
    color: #000;
    background-color: #fff;
    margin-bottom: 10px;
    border-collapse: collapse
}

.sb-calcContent-S3D-div table th {
    background-color: #0d6f96;
    color: #fff;
    width: 40px
}

.sb-calcContent-S3D-div table td {
    border: 1px #ddd solid
}

.sb-calcContent-S3D-div table tr:nth-child(1) {
    background: #0d6f96!important;
    color: #fff
}

.sb-calcContent-S3D-div table tr:nth-child(even) {
    background: rgba(212, 212, 212, .59)
}

.sb-shapeContent-S3D-div {
    position: absolute;
    top: 0;
    right: 140px;
    margin-right: 10px
}

.sb-shapeContent-S3D-div table {
    width: 150px;
    text-align: center!important;
    border: 1px #ddd solid;
    color: #000;
    background-color: #fff;
    border-collapse: collapse
}

.sb-shapeContent-S3D-div table th {
    background-color: #0d6f96;
    color: #fff
}

.sb-shapeContent-S3D-div table td {
    border: 1px #ddd solid
}

.sb-materialContent-S3D-div {
    position: absolute;
    bottom: 0;
    left: 0
}

.sb-materialContent-S3D-div table {
    width: 540px;
    text-align: center!important;
    border: 1px #ddd solid;
    color: #000;
    background-color: #fff;
    border-collapse: collapse
}

.sb-materialContent-S3D-div table th {
    background-color: #0d6f96;
    color: #fff
}

.sb-materialContent-S3D-div table td {
    border: 1px #ddd solid
}

.section-builder-report-container img {
    max-height: 400px
}

.sb-disabled-div-operations h5 {
    color: #fff
}

.sb-disabled-div-operations li,
.sb-disabled-div-operations span {
    color: #fff;
    font-size: 13px
}

.sb-disabled-div-operations img {
    display: block;
    margin: auto
}

.sb-disabled-div-operations button {
    height: 30px;
    background-color: #289dcc;
    border-radius: 3px;
    border-width: 0;
    color: #fff;
    font-weight: 700;
    font-size: 13px!important
}

#calc-wizard-menu {
    width: 25%;
    height: 72vh;
    overflow: auto;
    display: inline-block;
    font-size: 13px!important;
    padding: 0 10px;
    box-sizing: border-box;
    border-right: 1px solid #bbb
}

#calc-wizard-menu-title {
    font-size: 16px;
    color: #444;
    margin-bottom: 8px;
    display: none
}

#calc-wizard-menu span.calc-wizard-menu-link {
    display: block;
    cursor: pointer;
    color: #289dcc!important;
    font-weight: 700!important;
    padding: 4px;
    border-radius: 3px;
    font-size: 13px
}

#calc-wizard-menu span.calc-wizard-menu-link:hover {
    color: #333!important;
    background-color: #bcd8e3!important
}

#calc-wizard-menu span.calc-wizard-menu-link.menu-level2 {
    padding: 4px 4px 4px 25px;
    font-weight: 400!important
}

#calc-wizard-menu span.calc-wizard-menu-link.active {
    background-color: #00b6ff!important;
    color: #fff!important;
    font-weight: 700!important
}

#calc-wizard-main {
    width: 74%;
    height: 72vh;
    overflow: auto;
    display: inline-block;
    font-size: 13px!important;
    vertical-align: top;
    padding: 0 10px 0 25px;
    box-sizing: border-box
}

#calc-wizard-main-title {
    font-size: 18px;
    color: #444;
    margin-bottom: 0;
    display: block
}

#calc-wizard-main-subtitle {
    font-size: 14px;
    color: #444;
    margin-bottom: 0;
    font-weight: 200;
    display: block
}

.calc-wizard-main-divider {
    display: block;
    border-bottom: 1px solid #bbb;
    margin-bottom: 15px
}

#calc-wizard-main-content-loading {
    height: 80%;
    width: 100%;
    text-align: center;
    position: relative
}

#calc-wizard-main-content-loading img {
    height: 60px;
    width: 60px;
    margin: 0 auto;
    position: absolute;
    top: 50%
}

#sb-section-selector {
    width: 100%;
    padding: 6px;
    margin-bottom: 10px;
}

.control-panel-buttons {
    border-bottom: 1px solid #424242;
    background-color: #545454;
    padding-left: 5px;
    padding-right: 5px;
}

.control-panel-buttons .btn {
    font-size: 14px;
}

.btn-control {
    color: #fff;
    background-color: #545454;
    border-color: #6c757d;
    /*margin-top: 5px;*/
    /*margin-bottom: 5px;*/
}

.control-panel-buttons .btn {
    /*margin-top: 5px;*/
    /*margin-bottom: 5px;*/
}

.btn-control:hover {
    color: #fff;
    /*background-color: #424242;*/
    /*border-color: #424242;*/
}

.btn-control:focus, .btn-control.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-control.disabled, .btn-control:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-control:not(:disabled):not(.disabled):active, .btn-control:not(:disabled):not(.disabled).active,
.show > .btn-control.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242;
}

.btn-control:not(:disabled):not(.disabled):active:focus, .btn-control:not(:disabled):not(.disabled).active:focus,
.show > .btn-control.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-control-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn-control-light:hover {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-control-light:focus, .btn-control-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-control-light.disabled, .btn-control-light:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-control-light:not(:disabled):not(.disabled):active, .btn-control-light:not(:disabled):not(.disabled).active,
.show > .btn-control-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-control-light:not(:disabled):not(.disabled):active:focus, .btn-control-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-control-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.tooltip .tooltip-inner {
    background-color: #545454;
}

.tooltip .arrow:before {
    border-bottom-color:#545454!important;
    border-top-color:#545454!important;
}

#container-canvas {
    position: relative;
}

#container-canvas .control-left-panel {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #424242;
    background-color: #545454;
    width: 60px;
}

#section-builder-right-nav .tab-content {
    margin-top: 10px;
}

.btn-main-type-section {
    display: flex;
    background-color: #545454;
    border-color: #6c757d;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #6b757e;
    border-bottom: none;
    padding: 0.75rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    justify-content: space-between;
}

.tab-main-type-section .btn-main-type-section.btn-main-type-section__top-border {
    border-top-right-radius: 0.3em;
    border-top-left-radius: 0.3em;
}

.tab-main-type-section:not(.open) .btn-main-type-section.btn-main-type-section__bottom-border {
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
}

.btn-main-type-section:hover {
    color: #fff;
    background-color: #6b757e;
    border-color: #6b757e;
    border-top-color: #6b757e;
}

.btn-main-type-sections .tab-main-type-section:first-child:hover {
    border-color: #6b757e;
    border-top-color: #6b757e!important;
}


.btn-main-type-sections .tab-main-type-section:last-child:hover {
    border-color: #6b757e;
    border-top-color: #6b757e;
}

/*.btn-main-type-sections .tab-main-type-section:first-child .btn-main-type-section {*/
    /*border-top-left-radius: 0.25rem;*/
    /*border-top-right-radius: 0.25rem;*/
/*}*/

.btn-main-type-sections .tab-main-type-section:last-child .btn-main-type-section {
    /*border-bottom-left-radius: 0.25rem;*/
    /*border-bottom-right-radius: 0.25rem;*/
    border-bottom: 1px solid #6b757e;
}

.btn-main-type-section .btn-main-type-section__icon {
    display: flex;
    width: 50px;
}

.btn-main-type-section .btn-main-type-section__title {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    width: 73%;
    font-weight: 500;
}

.btn-main-type-section .btn-main-type-section__arrow
{
    display: flex;
    width: 20px;
    align-items: center;
    justify-content: space-between;
}

.tab-main-type-section__content {
    border: 1px solid #6b757e;
    display: none;
}

.tab-main-type-section.open .btn-main-type-section {
    color: #fff;
    background-color: #6b757e;
    border-color: #6b757e;
    border-top-color: #6b757e;
    border: 1px solid #6b757e;
}

.tab-main-type-section.open .tab-main-type-section__content {
    display: flex;
    flex-wrap: wrap;
}

.item-section {
    border: 1px solid #6b757e;
    padding: 5px;
    margin: 5px;
    /* margin-top: 5px; */
    /* margin-left: 5px; */
    text-align: center;
    flex-basis: 150px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.item-section:hover {
    background-color: #6b757e;
    color: #fff;
}

.item-section .item-section-icon {
    margin: 0 auto;
    text-align: center;
}

.item-section .item-section-title {
    align-items: center;
    display: flex;
    font-size: 14px;
    flex: 1;
    text-align: center;
    justify-content: center;
}

.section-builder-header {
    color: #ccc;
    border-bottom: 1px solid #6b757e;
    padding-bottom: 3px;
    margin-bottom: 10px;
}

.Select-control {
    font-size: 13px;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height: 20px;
}

.Select-clear {
    font-size: inherit;
}

.Select-arrow-zone {
    padding: 0 15px;
}

.Select-arrow {
    top: 5px;
    border-width: 15px 15px 10px;
}

.is-open > .Select-control .Select-arrow {
    top: 0;
    border-width: 0 15px 15px;
}

.Select-menu-outer, .Select-menu {
    max-height: none;
}

/* Кнопки управления холстом */
.container-canvas-actions {
    position: absolute;
    top: 23px;
    right: 20px;
    opacity: 0.7;
}

.container-canvas-actions .btn {
    cursor: pointer;
    outline: none;
    box-shadow: none;
    outline: none!important;
}

.container-canvas-actions .btn:focus, .container-canvas-actions .btn:active {
    outline: none!important;
    box-shadow: none!important;
}

.container-axis {
    position: absolute;
}

.dropdown-menu {
    font-size: 14px;
}

.dropdown-item {
    cursor: pointer;
}

ul.dropdown-menu input[type=checkbox], ul.dropdown-menu input[type=radio] {
    display: flex;
    align-items: stretch;
    justify-items: center;
    height: 23px;
}

ul.dropdown-menu li a {
    display: flex;
    text-decoration: none;
    color: #333;
    white-space: nowrap;
}

ul.dropdown-menu li a span {
    display: flex;
    text-decoration: none;
    padding-left: 5px;
}


ul.dropdown-menu .dropdown-item {
    padding: 5px 15px;
}

.vertical-buttons-splitter{
    height: 100%;
    border-left: 1px solid #6c757d;
    display: inline-block;
    margin-left: 5px;
}

.horizontal-buttons-splitter {
    height: 12px;
    display: table;
}

.btn-group-buttons {
    padding-left: 5px;
    box-sizing: border-box;
}

/*.btn-group-buttons .svg-inline--fa, .panel-instruments .svg-inline--fa {*/
    /*font-size: 17px;*/
    /*overflow: visible;*/
    /*margin-top: -4px;*/
    /*vertical-align: middle;*/
/*}*/

.shape-parameterts .form-check {
    position: relative;
    padding-left: 0;
    padding-bottom: 10px;
}

.shape-parameterts .form-check input[type="checkbox"] + label {
    padding-left: 5px;
}

.table.table-profile-parameters th, .table.table-profile-parameters td {
    padding: 5px 10px!important;
    font-size: 12px;
}

.table-profile-parameters {
    margin-top: 10px;
}

.table.table-stress th, .table.table-stress td {
    padding: 3px 10px!important;
    font-size: 12px;
    border-right: 1px solid #6c757c;
    text-align: center;
    vertical-align: middle;
}

.table.table-cloned-thead {
    background-color: #545454;
    border-bottom: 1px solid #6c757b;
}

.table.table-cloned-thead th {
    background-color: #545454;
}

.table.table-stress th {
    border-top: none!important;
    border-bottom: none!important;
}

.table.table-stress-1st tbody tr:first-child td {
    border-top: none;
}

.table-stress {
    margin-bottom: 0;
    color: #fff;
}

.error-wrapper{
    background-color: #dc3545;
    color: #fff;
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
    position: absolute;
    z-index: 999;
    bottom: calc(100% + 5px);
}

.has-error .numeric-input {
    border-color: #dc3545!important;
    background-color: #ffe7e9!important;
}

.btn-svg-icon {
    padding: 3px 10px;
}

.btn-svg-icon img {
    height: 20px;
}

.html-report br {
    /*content: "";*/
    /*margin: 2em;*/
    /*display: block;*/
    /*font-size: 24%;*/
}


@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.overlay_loading {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #545454;
    z-index: 1000;
    opacity: 0.7;
}

.container-sidebar .overlay_loading {
    width: 350px!important;
    opacity: 1!important;
}

.overlay_loading__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay_loading__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner_loading {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 5px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.container-sidebars {
    height: calc(100vh - 46px);
}

.container-sidebars .react-draggable, .container-sidebar {
    max-height: calc(100vh - 46px);
}

.container-sidebars .react-draggable, .container-sidebar {
    position: absolute;
    z-index: 10;
    top: 0;
    height: calc(100vh - 46px);
}

.container-sidebars.container-sidebars-attracted .container-sidebar {
    min-height: calc(100vh - 40px);
}

.container-sidebars.container-sidebars-attracted .container-sidebar {
    border: none!important;
}

.container-sidebars:not(.container-sidebars-attracted) .sidebar-header-panel  {
    cursor: move;
}

.container-sidebars.container-sidebars-attracted {
    background-color: #545454;
}

.container-sidebars:not(.container-sidebars-attracted) .container-sidebar-inner {
    max-height: calc(100vh - 110px);
}

.sidebar-header-panel {
    padding: 10px 15px 10px 15px;
    background-color: #424242;
    position: fixed;
    width: 349px;
    height: 35px;
    z-index: 1000;
}

.sidebar-header-panel .sidebar-header-panel-icon {
    cursor: pointer;
    margin-left: 5px;
    float: left;
    display: block;
    font-size: 12px;
    line-height: 20px;
}

.sidebar-header-panel .panel-icons {
    margin-top: -5px;
    position: absolute;
    right: 14px;
    top: 10px;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon {
    opacity: 0.5;
    text-decoration: none;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon a {
    text-decoration: none!important;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon:hover {
    opacity: 1;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon.fa-window-restore {
    line-height: 20px;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon.fa-times {
    font-size: 18px;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon.fa-arrows-alt {
    font-size: 12px;
    line-height: 22px;
}

.sidebar-header-panel .panel-icons .sidebar-header-panel-icon.fa-angle-double-right,
.sidebar-header-panel .panel-icons .sidebar-header-panel-icon.fa-angle-double-left{
    font-size: 12px;
    line-height: 22px;
}

.sidebar-title {
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 14px;
    font-size: 14px;
}

.sidebar-title span {
    font-size: 14px;
    color: #fff!important;
    font-weight: 500;
}

.dashed-bottom {
    border: none;
    border-bottom: 1px dashed;
    text-decoration: none!important;
}

.btn-link {
    color: #ffffff;
    background-color: transparent;
}

.btn-link:hover, .btn-link:active, .btn-link:focus {
    color: #ffffff;
    background-color: transparent;
    text-decoration: none;
}

.switch-wrapper {
    margin-top: 7px;
    margin-bottom: 10px;
}

.switch-wrapper.switch-wrapper_disabled {
    opacity: 0.5;
}

.switch-wrapper .switch input {
    display:none;
}

.switch-wrapper .switch {
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(2px) translateX(-17px);
    position: absolute;
    right: 0;
}

.switch-wrapper .switch.switch-disabled {
    opacity: 0.5;
}

.switch-wrapper .switch-label {
    display: inline-block;
    margin-left: 0px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 50px;
}

.switch-wrapper .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 4px #fff;
    cursor: pointer;
    border: 0 solid transparent;
    box-shadow: 0 0 0 2px #6c757c;
    background-color: #6c757c;
    overflow: hidden;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-wrapper input:checked + .slider {
    -webkit-box-shadow: 0 0 0 2px #2eaf4b, 0 0 4px #2eaf4b;
    box-shadow: 0 0 0 2px #2eaf4b, 0 0 4px #2eaf4b;
    background: #2eaf4b;
}

.switch-wrapper .slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
}

.switch-wrapper input:checked + .slider:before {
    transform: translateX(20px);
    background: #fff;
}

.flip-horizontal {
    -webkit-transform: scale(-1,1);
    transform: scale(-1,1);
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: "FlipH";
}

.btn-group-buttons-input .btn {
    height: 34px!important;
    margin-top: 0px!important;
    padding: 0.175rem 0.175rem;
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 8px;
}

.btn-sm {
    height: 34px!important;
    margin-top: 0px!important;
    font-size: 14px!important;
    line-height: 1.5!important;
}

.btn-control.focus, .btn-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-control:not(:disabled):not(.disabled).active:focus, .btn-control:not(:disabled):not(.disabled):active:focus, .show>.btn-control.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn:focus {
    outline: none!important;
    box-shadow: none!important;
}

.custom-control.custom-checkbox .custom-control-label {
    line-height: 27px;
}

.custom-control.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 15px;
    height: 15px;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60% 60%;
}

.custom-control.custom-checkbox .custom-control-label::after {
    position: absolute;
    top: 4px;
    display: block;
    width: 15px;
    height: 15px;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60% 60%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #28a745!important;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow:none !important;
}

.container-sidebar .custom-control-input~.custom-control-label {
    color: #fff;
}

.container-sidebar .custom-control-input:disabled~.custom-control-label {
    opacity: 0.5;
}

/* Стили раскрывающейся панели */
.Collapsible-wrapper {
    min-width: 100%;
}

.Collapsible {}

.Collapsible-wrapper .Collapsible:last-child {
    border-bottom: 1px solid #6c757b;
}

.Collapsible-pressure-item .Collapsible:last-child {
    border-bottom: none;
}

.Collapsible.Collapsible__isset {}

.Collapsible .Collapsible__isset {
    width: 7px;
    height: 7px;
    margin-top: 7px;
    margin-left: 7px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    background-color: #28a745;
    position: absolute;
}

.Collapsible__contentInner {
    padding: 0 15px;
    border-top: 1px solid #424242;
    border-top: 0;
}

.Collapsible__contentInner p {
    margin-bottom: 10px;
    line-height: 20px;
}

.Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}

.Collapsible__trigger {
    cursor: pointer;
    display: block;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    border-top: 1px solid #6c757b;
    padding: 10px 15px;
    background: #545454;
    color: white;
}

.Collapsible__clear-border-top .Collapsible__trigger {
    border-top: none!important;
}

.Collapsible__trigger.is-closed + .Collapsible__contentOuter .switch-wrapper {
    opacity: 0;
}

.Collapsible__trigger:after {
    cursor: pointer;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-chevron-down fa-w-14 fa-3x'%3E%3Cpath fill='%23ffffff' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' class=''%3E%3C/path%3E%3C/svg%3E");
    position: absolute;
    right: 15px;
    top: 13px;
    opacity: 0.5;
    transform: scale(0.8);
    height: 15px;
    width: 13px;
    display: block;
    transition: transform 300ms;
}

.Collapsible__trigger:hover:after {
    opacity: 1;
}

.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg) scale(0.8);
}

.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}

.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
}

.Collapsible-panel .Collapsible-panel__header {
    display: block;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    border-top: 1px solid #6c757b;
    padding: 10px 15px 10px 25px;
    background: #545454;
    margin-right: -15px;
    color: #fff;
}

.Collapsible-panel .Collapsible-panel__content {
    display: none;
}

.Collapsible-panel .Collapsible-panel__header .Collapsible-panel__header__icon {
    cursor: pointer;
    opacity: 0.5;
    font-size: 12px;
    margin-left: 10px;
}

.Collapsible-panel .Collapsible-panel__header .Collapsible-panel__header__icon:hover {
    opacity: 1;
}

.overflow-visible .Collapsible__contentOuter {
    overflow: visible!important;
}

.pressure-data-item {
    position: relative;
    margin-left: 10px;
}

.pressure-data-item .select-pressure {
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: -25px;
}

.pressures-items {
    position: relative;
}

.pressures-items .select-all-pressures {
    cursor: pointer;
    position: absolute;
    top: -7px;
    left: 0px;
}

.container-modals {
    position: absolute;
    z-index: 1000;
}

.container-modals .modal-dialog {
    position: absolute!important;
}

.container-modals .modal-content {
    background-color: #545454;
    color: #ffffff;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 0;
}

.container-modals .modal-header {
    background-color: #424242;
    border-bottom: none;
    border-radius: 0;
    cursor: pointer;
    padding: 10px 15px 10px 15px;
}

.container-modals .modal-header .modal-title {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    color: #fff!important;
    font-weight: 500;
}

.container-modals .modal-header .panel-icons {
    margin-top: -5px;
    position: absolute;
    right: 14px;
    top: 10px;
}

.container-modals .modal-header .modal-header-panel-icon {
    cursor: pointer;
    margin-left: 5px;
    float: left;
    display: block;
    line-height: 20px;
    color: #fff;
    text-decoration: none;
    opacity: 0.5;
}

.container-modals .modal-header .modal-header-panel-icon:hover {
    opacity: 1;
}

.container-modals .modal-header .modal-header-panel-icon.fa-times {
    font-size: 18px;
}

.container-modals .modal-body {
    padding: 15px;
    font-size: 14px;
}

.container-modals .modal-footer {
    border-top: 1px solid #424242;
    padding: 5px 15px;
}

.button-group-multiline .btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.items-list-container {}

.items-list-container .item-list-row {
    padding: 10px 0;
    border-bottom: 1px solid #6c757b;
    font-size: 13px;
}

.items-list-container .item-list-row:last-child {
    border-bottom: none!important;
}

.items-list-container .item-list-row .item-list-row__title {
    width: 240px;
}

.items-list-container .item-list-row .item-list-row__controls {}

.items-list-container .item-list-row .item-list-row__icon {
    cursor: pointer;
    opacity: 0.5;
    font-size: 14px;
}

.items-list-container .item-list-row .item-list-row__button {
    margin-left: 10px;
}

.items-list-container .item-list-row .item-list-row__icon:hover {
    opacity: 1;
}

/* Layout
------------ */

.d3-context-menu {
    position: absolute;
    min-width: 150px;
    z-index: 1200;
}

.d3-context-menu ul,
.d3-context-menu ul li {
    margin: 0;
    padding: 0;
}

.d3-context-menu ul {
    list-style-type: none;
    cursor: default;
}

.d3-context-menu ul li {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

/*
	Disabled
*/

.d3-context-menu ul li.is-disabled,
.d3-context-menu ul li.is-disabled:hover {
    cursor: not-allowed;
}

/*
	Divider
*/

.d3-context-menu ul li.is-divider {
    padding: 0;
}

/* Theming
------------ */

.d3-context-menu-theme {
    background-color: #f2f2f2;
    border-radius: 4px;

    font-family: Arial, sans-serif;
    font-size: 14px;
    border: 1px solid #d4d4d4;
}

.d3-context-menu-theme ul {
    margin: 4px 0;
}

.d3-context-menu-theme ul li {
    padding: 4px 16px;
}

.d3-context-menu-theme ul li:hover {
    background-color: #4677f8;
    color: #fefefe;
}

/*
	Header
*/

.d3-context-menu-theme ul li.is-header,
.d3-context-menu-theme ul li.is-header:hover {
    background-color: #f2f2f2;
    color: #444;
    font-weight: bold;
    font-style: italic;
}

/*
	Disabled
*/

.d3-context-menu-theme ul li.is-disabled,
.d3-context-menu-theme ul li.is-disabled:hover {
    background-color: #f2f2f2;
    color: #888;
}

/*
	Divider
*/

.d3-context-menu-theme ul li.is-divider:hover {
    background-color: #f2f2f2;
}

.d3-context-menu-theme ul hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*
	Nested Menu
*/
.d3-context-menu-theme ul li.is-parent:after {
    border-left: 7px solid transparent;
    border-top: 7px solid red;
    content: "";
    height: 0;
    position: absolute;
    right: 8px;
    top: 35%;
    transform: rotate(45deg);
    width: 0;
}

.d3-context-menu-theme ul li.is-parent {
    padding-right: 20px;
    position: relative;
}

.d3-context-menu-theme ul.is-children {
    background-color: #f2f2f2;
    border: 1px solid #d4d4d4;
    color: black;
    display: none;
    left: 100%;
    margin: -5px 0;
    padding: 4px 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.d3-context-menu-theme li.is-parent:hover > ul.is-children {
    display: block;
}

.container-panel-instruments {
    float: left;
    width: 47px;
    z-index: 10;
    padding: 5px;
    background-color: #545454;
}

.container-panel-instruments .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px!important;
    height: 37px!important;
    font-size: 16px;
    padding: 5px;
    margin-bottom: 5px;
}

.container-panel-fast-open-windows {
    float: left;
    width: 110px;
    z-index: 10;
    padding: 5px;
    background-color: #545454;
    border-left: 1px solid #424242;
    height: 100%;
}

.container-panel-fast-open-windows .btn {
    display: flex;
    justify-content: center;
    width: 100% !important;
    min-height: 37px;
    height: auto!important;
    white-space: pre-line!important;
    font-size: 16px;
    padding: 5px;
    border: 1px solid #6c757b;
    margin-bottom: 5px;
}

.container-panel-fast-open-windows .btn.btn-success {
    border: none!important;
}

/*.container-panel-instruments .btn.active {*/
    /*color: #fff!important;*/
    /*background-color: #28a745!important;*/
    /*border-color: #28a745!important;*/
/*}*/

.panel-instruments .flaticon-grid {
    font-size: 16px;
    padding: 0;
}

.panel-instruments .fa-draw-square {
    font-size: 19px;
    padding: 0;
}

.panel-instruments {
    background-color: #545454;
    color: #fff;
    font-size: 12px;
}

.panel-instruments .button-instrument {
    width: 50px;
    height: 50px;
    text-align: center;
}

.btn-toggler.active  {
    background-color: #545454!important;
    box-shadow: inset 1px 1px 4px #333333!important;
    border: 1px solid #6d757d!important;
}

.selection {
    fill: #ADD8E6;
    stroke: #ADD8E6;
    fill-opacity: 0.3;
    stroke-opacity: 0.7;
    stroke-width: 2;
    stroke-dasharray: 5, 5;
}

.selection.touch {
    fill: #D8ADE6;
    stroke: #D8ADE6;
}

/* Panel gradient */
.container-panel-gradient-scale {
    position: absolute;
    bottom: 60px;
    width: 100%;
}

.container-panel-gradient-scale .panel-gradient-scale .gradient-scale {
    position: relative;
    float: left;
    height: 25px;
    border: 1px solid #333333;
    margin-left: -1px;
}

.container-panel-gradient-scale .panel-gradient-scale .gradient-scale .gradient-color {
    width: 100%;
    height: 100%;
}

.container-panel-gradient-scale .panel-gradient-scale .gradient-value {
    position: relative;
    height: 25px;
    text-align: center;
}

.container-panel-gradient-scale .panel-gradient-scale .gradient-value .gradient-value {
    color: #000000;
    font-size: 12px;
    text-align: center;
}

.bootbox.modal .modal-content .modal-title {
    position: absolute;
}

/*.container-sidebar .dropdown:hover > .dropdown-menu {*/
/*    display: block;*/
/*}*/
/*.container-sidebar .dropdown > .dropdown-toggle:active {*/
/*    !*Without this, clicking will make it sticky*!*/
/*    pointer-events: none;*/
/*}*/

/*.container-sidebar .dropdown-menu {*/
/*    margin-top: 0!important;*/
/*}*/

.container-sidebar .dropdown-menu a {
    color: #333;
}

.card {
    border-radius: 0.25rem !important;
}

.card-footer:last-child {
    border-radius: 0 0 0.25rem 0.25rem !important;
}

.card-img-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.ssc-cp-swatch {
    padding: 2px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
}

.ssc-cp-color {
    width: 48px;
    height: 16px;
    border-radius: 2px;
}

.ssc-clickable {
    cursor: pointer;
}

.rs-icon-info {
    opacity: 0.5;
}

.rs-popover-content {
    color: #333;
}

/*.custom-input__container .ci_measure {*/
/*    color: #333;*/
/*}*/

.vtabs .nav-item a, .vtabs .nav-item a:hover {
     color: #212529;
     text-decoration: none;
}

.container-vtabs-menu {
    max-height: 100%;
    overflow: scroll;
}

.container-report-item h2 {
    margin-top: 50px;
}

.container-report-item h2:first-child {
    margin-top: 10px;
}