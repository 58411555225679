.ReactTable .rt-thead.-headerGroups{
  background: none;
  box-shadow: none;
}
.ReactTable .rt-thead.-header{
  background: none;
  height: 80px;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  font-size: 12px;
  border:none;
  height: 100%;
}
.rt-body{
  padding-top: 1px;
}
.fz-12{
  font-size: 12px!important;
}
.survaysTable{
  margin: 0 auto;
  /*width: 1280px;*/
  flex-direction: column;
  display: flex;
}
.survaysTable .ReactTable *{
  text-align: center;
  white-space: normal;
}
.survaysTable .ReactTable .rt-thead .rt-resizable-header{
  box-shadow: none;
  height: 70px;
}
.layouts-controll{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.layouts-controll div{
  cursor: pointer;
  display: block;
  width:35px;
  padding: 5px;
}
.layouts-controll img{
  width:100%;
}
.input-text--surveys{
  height: 20px;
  width: 40px;
}
.error-table{
  border: 1px solid red!important;
}
.rt-noData{
  pointer-events: auto!important;
  top: 70%!important;
}
.pointer{
  cursor: pointer!important;
}
.table-field{
  margin-top: 1px;
  border: 1px solid rgba(0,0,0,0.1);
  background: none;
}
.rt-tbody::-webkit-scrollbar {
  width: 0.5px;
}
.hide-cell{
  opacity: 0!important;
  pointer-events: none!important;
}

.survaysTable .ReactTable .table-field {
    margin-top: 0!important;
}

/*.survaysTable .flex-column .table-field:first-child {*/
/*    border-top: none;*/
/*}*/

/*.survaysTable .flex-column .table-field {*/
/*    border-left: none;*/
/*    border-right: none;*/
/*}*/

.survaysTable .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid #f9f9f9!important;
}