.ReactTable.loadsTable *{
    white-space: normal;
}
.ReactTable.loadsTable .rt-thead.-header{
    height: 50px;
}
.ReactTable.loadsTable .rt-thead .rt-th{
    font-size: 14px;
    height: 50px;
}
.ReactTable.loadsTable .table-field{
    border: 1px solid #e5e5e5;
    background: none;
    width: 100%;
    text-align: center;
}
.contoll-buttons div{
    cursor: pointer;
    display: block;
    width:35px;
    padding: 5px;
    font-size: inherit;
}
.contoll-buttons img{
    width:100%;
}
.contoll-buttons{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.ReactTable .rt-td {
    padding: 0 1px 2px 1px;
    vertical-align: middle;
}

.ReactTable.loadsTable .rt-td select {
    /*border: none!important;*/
    /*border-radius: 0!important;*/
}

.ReactTable.loadsTable .rt-td select {
    border-radius: 0!important;
    margin-top: 1px;
    border-color: #e5e5e5;
}

.ReactTable .rt-tbody .rt-tr-group {
    padding: 0!important;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid #e5e5e5!important;
}

.ReactTable.loadsTable .table-field {
    border: 1px solid #e5e5e5;
    background: none;
    width: 100%;
    text-align: center;
    outline: none;
    height: 35px!important;
}

.ReactTable .rt-thead.-header {
    /* box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%); */
    box-shadow: none;
    border-bottom: 1px solid #e5e5e5;
}

.app-page-title {
    font-size: 23px;
    line-height: 23px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
}