.container-report-item {
    max-height: 100%;
    overflow: scroll;
}

.container-report-item::-webkit-scrollbar {
    width: 5px;
    background-color: #545454;
}

.container-report-item::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.3);
    background-color: #424242;
}

.container-report-item::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: #545454;
}

.chartSvg{
    min-width: 620px;
}
.chart2Svg{
    text-align: center;
}
.content_wrapper h2{
    text-align: center;
}
.chart4Svg{
    text-align: center;
    margin: 0 auto;
}
.w-60{
    width: 60%;
}
.m-0{
    margin: 0;
}
.px-3{
    padding-left: 50px;
    padding-right: 50px;
}
.bg-fill{
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.1;
}
.page{
    position: relative;
    font-size: 14px;
    margin-bottom: 0.5cm;
    width: 21cm;
    display: block;

}
.page p{
    text-align: justify;
}
.page{
    padding-right: 0.6cm;
    padding-left: 2.1cm;
}
.moments-img{
    margin: 20px 0;
}
.chart{
    text-align: center;
}
.chart img {
    height: 300px;
    width: 400px;
    margin-right: 49px;
}
.container_formula-number{
    position: absolute;
    right: 0;
    top: 0;
}
.container_formula{
    position: relative;
}
.hi-Lines{
    transform: rotate(45deg);
    transform-origin: 10px 145px;
}
.init-data {
    width: 100%;
}
.init-data p{
    margin: 0;
}
.init-data td {
    vertical-align: top;
}
.chart3Img{
    margin-top: 15px;
    margin-left: 40px;
    position: relative;
    bottom: -5px;
}
.text-center{
    text-align: center!important;
    margin: 10px 0;
}
.t-c{
    text-align: center!important;
}
.w-35{
    width:35%
}
.w-50{
    width: 50%;
}
.w-45{
    width: 45%;
}
.w-25{
    width: 25%;
}
.title-page{
    font-family: Arial, sans-serif;
    font-size: 14px;
    height: 29.8cm;
    padding-left: 1cm;
    padding-right: 0.5cm;
}
.prj{
    margin: 50px;
}
.title-page h2,.title-page h3{
    font-weight: normal;
    text-align: center;
    font-size: 16px;
}
.title-page h3{
    margin-bottom: 75px;
    text-align: center;
    font-weight: bold;
}
.title-page h4{
    font-size: 14px;
    text-align: center;
    font-weight: bold;
}
.title-page h5{
    font-size: 14px;
    text-align: center;
    font-weight: normal;
}
.mt-pt{
    margin-top: 150px;
}
.mt-name2{
    margin-top: 30px;
}
.MathJax,.container_formula{
    /*pointer-events: none;*/
}
.tools{
    position: fixed;
    top: 25px;
    left: 25px;
}
.tools .print{
    cursor: pointer;
    padding: 10px;
    border: 1px solid #242424;
    border-radius: 5px;
    background-color: #fff;
}
@media print {
    .page-break {
        display: block;
        page-break-before: always;
    }
    .tools{
        display: none;
    }
    size: A4 portrait;

}

@media print {
    body {
        margin: 0;
        padding: 0;
    }
    .page {
        box-shadow: none;
        margin: 0;
        width: auto;
        height: auto;
    }
    .noprint {
        display: none;
    }
    .enable-print {
        display: block;
    }
}