.icon{
  width: 20px;
  cursor: pointer;
}
.react-grid-HeaderCell{
    font-size: 14px!important;
}
.rdg-cell-action-button{
    width: 35px;
}
.disabled{
    opacity: 0.4;
    pointer-events:none;
}
.schemeButton{
    cursor: pointer;
}
.normal-num p:nth-child(2){
    font-size: 12px;
    margin: 10px 0;
}
.normal-num p:first-child{
    margin: 0;
}
.normal-num p{
    font-size: 12px;
    margin: 16px 0;
}
.layoutsParams [role='gridcell']{
    padding: 0;
}
.layouts-control img{
    width: 20px;
    cursor: pointer;
    margin: 2px auto;
}
.icon--surveys{
    margin: 20px 1px 0 0;
  }
.hidden{
    display:none;
}
.btn-group.btn-group--ml5{
    margin-left:5px;
}
.control-panel{
    border-bottom: 1px solid #3a3a3a;
    background-color: #545454;
    padding-left: 5px;
    padding-right: 5px;
}
.btn-control{
    color: #fff;
    background-color: #545454;
    border-color: #6c757d;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.my-5px{
    margin-bottom: 5px;
    margin-top: 5px;
}
#control1 + label{
    border-radius:0.25rem 0 0 0.25rem;
}

.container-control-steps label {
    border: 1px solid #6c757a;
}

.btn-control:hover{
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a;
}
body{
    background-color: #FAFAFA!important;
    height: 100vh;
}
.ci_measure{
    width: auto;
    font-size: 15px;
    position: absolute;
    bottom: 5px;
    right: 28px;
}
.custom-input{
    position: relative;
    display: inline-block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.custom-input__container{
    position: relative;
}
.custom-input input{
    width:100%;
    text-align: center!important;
}
.ReactTable .rt-tbody .rt-tr-group{
    border-bottom: solid 1px rgba(0,0,0,0.15);
    padding: 7.5px 0;
}
.fade {
    opacity: 1!important;
}