[name='checkTabs']{
    display: none;
}
[name='checkTabs'] + label{
    display: flex;
}
[name='checkTabs'] + label p{
    line-height: 27px;
    margin: 0;
}
#controlCheck1 + label{
    border-radius:0.25rem 0 0 0.25rem;
}
[name='checkTabs']:checked + label{
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a;
}
[disabled='true']:checked + label{
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a;
}
.check-control .rs-checkbox-wrapper{
    left: 0;
    top: 5px;
}
.check-control .rs-checkbox-checker{
    min-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.min-vh-75{
    min-height: 75vh;
}
.select-small{
    width: 175px;
}
.inline-block{
    display: inline-block;
}
.anchor-img{
    width: 70px;
    height: 70px;
    border-radius: 5px;
}
.hide{
    display:none;
}
[name = 'конструкция_болта']{
    display: none;
}
[name='конструкция_болта'] + label img{
    border-radius: 5px;
}

[name='конструкция_болта'] + label{
    box-sizing: border-box;
    border:2px solid #c1c1c1;
    border-radius: 5px;
    transition: 0.4s;
    cursor: pointer;
}
[name='конструкция_болта']:checked + label{
    box-sizing: border-box;
    border:2px solid #323232;
    border-radius: 5px;
    transition: 0.4s;
}
.anchor-scheme{
    cursor: pointer;
    text-decoration:underline;
    margin: 0;
    padding: 0;
    color: darkslateblue;
    font-size: 10px;
    position: relative;
    bottom: 5px;
}
.imgS{
    position: absolute;
    width: 100%;
    height: 100%;
    /*top: 100px;*/
    /*left: 50px;*/
}
.imgS *{
    position: absolute;
    width: 100%;
    height: auto;

}
#lowTraversaSvg{
    bottom: 100px;
    left: 10px;
    width:100%;
    opacity: 0;
    transition: 0.2s;
}
#highTraversaSvgL,#highTraversaSvgB{
    opacity: 0;
    position: absolute;
    height: 600px;
    bottom: 0;
}
#svg-object{
    position: absolute;
    opacity: 0;
    bottom: 150px;
}
.text-y-middle{
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}

.react-tabs__tab--selected{
    background: none;
}