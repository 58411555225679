* {
    margin: 0;
    padding: 0;
}

.app-main-window {
    height: 100vh;
    /*background-color: #545454;*/
}

.main-window-container {
    position: relative;
    height: calc(100% - 46px);
    /*text-align: center;*/
    /*background-color: #545454;*/
}

.canvas-board-container {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
}

.canvas-board-container::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.canvas-board-container #my_iframe::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.canvas-board-container input.dimension-input[type=text] {
    font-weight: bolder;
    width: 60px!important;
    border: none;
    background-color: rgba(100, 100, 200, .1);
    color: #00f;
    font-size: 12px!important;
    position: absolute!important;
    z-index: 50!important;
    padding: 3px 6px!important;
    margin: 0!important;
    max-width: 200px!important
}

select *:disabled{
    background-color: #c9c8c8;
    color: #000;
}

.btn-spoiler{
    padding: 5px;
}

.schemeButton{
    cursor: pointer;
}
.pa-top25{
    position: absolute;
    top: 25%;
}
.fz-14{
    font-size: 14px;
}
.fz-12{
    font-size: 12px;
}
.h-30px{
    height: 30px!important;
}

.w-30{
    width: 30%!important;
}

.w-40 {
    width: 40%!important;
}

.w-50 {
    width: 50%!important;
}

.w-60 {
    width: 60%!important;
}

.lh-16 {
    line-height: 16px!important;
}

.lh-30{
    line-height: 30px!important;
}
.lh-35{
    line-height: 35px!important;
}
.h-50{
    height: 50px;
}
label{
    font-size: 14px!important;
}
.nav-tabs .nav-link{
    /*width: 30%;*/
    text-align: center;
}
.ReactTable .rt-thead {
    overflow-y: hidden;
  }
.icon{
  width: 20px;
  cursor: pointer;
}
.react-grid-HeaderCell{
    font-size: 14px!important;
}
.rdg-cell-action-button{
    width: 35px;
}
.disabled{
    opacity: 0.4;
    pointer-events:none;
}

.normal-num p:nth-child(2){
    font-size: 12px;
    margin: 10px 0;
}
.normal-num p:first-child{
    margin: 0;
}
.normal-num p{
    font-size: 12px;
    margin: 16px 0;
}
.layoutsParams [role='gridcell']{
    padding: 0;
}
.layouts-control img{
    width: 20px;
    cursor: pointer;  
    margin: 2px auto;
}
.icon--surveys{
    margin: 20px 1px 0 0;
  }
.hidden{
    display:none;
}
.btn-group.btn-group--ml5{
    margin-left:5px;
}
.control-panel{
    border-bottom: 1px solid #3a3a3a;
    background-color: #545454;
    padding-left: 5px;
    padding-right: 5px;
}
.btn-control{
    color: #fff;
    background-color: #545454;
    border-color: #6c757d;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}
.my-5px{
    margin-bottom: 5px;
    margin-top: 5px;
}

[name='tabs']:checked + label {
    color: #fff;
    background-color: #545454;
    border-color: #6c757a;
}

[name='foundationType']:checked + label{
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a;
}
#control1 + label{
    border-radius:0.25rem 0 0 0.25rem;
}
.btn-control:hover{
    color: #fff;
    background-color: #3a3a3a;
    border-color: #3a3a3a;
}
[name='tabs']{
    display: none;
}

body {
    background-color: #FAFAFA!important;
    /*color: #ffffff;*/
    height: 100vh;
    font-size: 14px;
}

.btn-custom.active {
    z-index: 0!important;
    color: #242424;
    background-color: #c9c8c8;
    border-color: #505050;
}
.btn-custom{
    cursor: pointer;
    color: #333333!important;
    background-color: #fff;
    border-color: #505050;
    padding: 5px 20px;
}
.ci_measure{
    width: auto;
    font-size: 15px;
    position: absolute;
    bottom: 5px;
    right: 28px;
}

.custom-input{
    position: relative;
    display: inline-block;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.custom-input__container {
    position: relative;
    align-self: center;
}

.custom-input input{
    width:100%;
    text-align: center!important;
}

.ReactTable .rt-tbody .rt-tr-group{
    border-bottom: none;
    padding: 7.5px 0;
}

.ReactTable.loadsTable .table-field:focus {
    border: 2px solid #2260c5;
    border-radius: 5px;
}

.rs-notification{
    z-index: 1000;
}